import React from 'react'
import { apartmentDistrict } from '../../../utils/StaticData'
import DistrictCard from '../../cards/DistrictCard'

type Props = {
    lang: any
    langCode: string
}

const FindApartmentSection = ({ lang, langCode }: Props) => {
    return (
        <>
            <h3 className="text-2xl mb-5  font-bold">{lang.subtitle_area}</h3>
            <div className='flex flex-row flex-no-wrap lg:flex-wrap overflow-y-scroll lg:overflow-hidden rounded-lg gap-5 lg:gap-2' style={{ scrollSnapType: "x mandatory" }}>
                {apartmentDistrict.map(({ url, img, location }, idx) =>
                    <DistrictCard
                        key={idx}
                        url={url}
                        img={img}
                        location={location}
                        lang={lang}
                        lang_code={langCode}
                    />
                ).filter((_, index) => index < 10)}
            </div>
        </>
    )
}

export default FindApartmentSection