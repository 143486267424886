import classes from './DistrictCard.module.css'
import { generateUrl } from '../../utils/Helper'
import Img from '../Img'

interface IProps {
    lang: any
    lang_code: string
    url: string
    img: string
    location: string
}

const DistrictCard = ({ lang, lang_code, url, img, location }: IProps) => {

    return (
        <div className={`flex-shrink-0 ${classes.width}`} style={{ scrollSnapAlign: 'start' }}>
            <a href={generateUrl(lang_code, url.replace('apartemen', lang_code === 'en' ? 'apartment' : 'apartemen'))} className="relative w-full block rounded-lg md:rounded-none overflow-hidden">
                <div className="relative w-full" style={{ paddingTop: '96.4%' }}>
                    <Img quality={90} src={img} alt={lang[location] || location} className="w-full h-full object-cover rounded-lg md:rounded-none" />
                </div>
                <p className={`absolute bottom-0 left-0 right-0 py-6 text-white font-semibold text-center leading-tight text-xl lg:text-lg`}>
                    {lang[location] || location}
                </p>
            </a>
        </div>
    )
}

export default DistrictCard